<template>
  <div>
    <v-row
        class="match-height"
        style="margin-bottom: 2%;"
    >
      <v-col
          cols="12"
          sm="12"
      >
        <statistics-card-vertical
            :color="totalProfit.color"
            :icon="totalProfit.icon"
            :statistics="String(customer_list.length)"
            :stat-title="totalProfit.statTitle"
            :subtitle="totalProfit.subtitle"
        ></statistics-card-vertical>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="customer_list"
      item-key="id"
      class="table-rounded"
      :search="search"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      @pagination="getItemesByPage"
    >
      <template #top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Liste des Clients</v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="filtreTable"
              dense
              :items="listCity"
              label="Filtré par Ville"
              outlined
              @change="filterCustomer"
            ></v-select>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="6"
            sm="4"
          >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="createCustomer=true"
            >
              Nouveau Client
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            sm="4"
          >
            <v-btn
                color="primary"
                dark
                class="mb-2"
                v-if="customer_list.length > 0"
                @click="exportToExcel"
            >
              Exporter au format Excel
            </v-btn>
          </v-col>
          <!--          class="d-flex"-->
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="search"
              dense
              append-icon="mdi-magnify"
              label="Rechercher un client"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="width: 100%;"
        >
          Details du Client:  <br>
          <p><span>Date Anniversaire</span> :<strong>{{ item.cust_birthday }}</strong></p>
          <p><span>Lieu de Naissance</span> :<strong>{{ item.cust_birth_place }}</strong></p>
          <p><span>Nom du pere</span> :<strong>{{ item.cust_father_name }}</strong></p>
          <p><span>Nom de la mere</span> :<strong>{{ item.cust_mother_name }}</strong></p>
          <p><span>Nom de caresse</span> :<strong>{{ item.cust_pseudonym }}</strong></p>
          <p><span>Pays</span> :<strong>{{ item.cust_country }}</strong></p>
          <p><span>Ville</span> :<strong>{{ item.cust_city }}</strong></p>
          <p><span>Date d'expiration de la piece</span> :<strong>{{ item.cust_id_expiration_date }}</strong></p>
          <p><span>Reference de la piece</span> :<strong>{{ item.cust_reference_id }}</strong></p>
          <p><span>Profession</span> :<strong>{{ item.cust_job }}</strong></p>
          <p><span>Domicile</span> :<strong>{{ item.cust_place_residence }}</strong></p>
          <p><span>Date d'entre en cote d'ivoire</span> :<strong>{{ item.cust_date_of_entering_ivory_coast }}</strong></p>
          <div style="display:flex; justify-content:space-between; align-items: start; padding: 1rem;">
            <v-btn
              color="primary"
              small
              @click="modify_customer(item)"
            >
              Modifier
            </v-btn>
          </div>
        </td>
      </template>
    </v-data-table>
    <!--    dialog to create client begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="createCustomer"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="createCustomer = false"
              >
                <mdi-close-circle />Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Enregistrer un nouveau Client
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <CustomeRegister @success-customer="closeCustomerRegister"></CustomeRegister>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to create client end-->

    <!--    dialog to modify client begin-->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="modifyCustomer"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                color="primary"
                @click="modifyCustomer = false"
              >
                <mdi-close-circle />Annuler
              </v-btn>&emsp;
              <v-toolbar-title color="white">
                Modifier les informations de ce Client
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <CustomerModified @success-modif="closeModifCust" :customer_old="customer_selected"></CustomerModified>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!--    dialog to modify client end-->
  </div>
</template>

<script>
import { mdiDotsVertical,mdiCloseCircle, mdiAccount, mdiSquareEditOutline } from '@mdi/js'
// eslint-disable-next-line import/extensions
import CustomeRegister from '@/views/customer/CustomeRegister'
// eslint-disable-next-line import/extensions
import CustomerModified from '@/views/customer/CustomerModified'

import StatisticsCardVertical from "@/views/customer/StatisticsCardVertical";
import {json2excel, excel2json} from 'js2excel';

import * as components from 'vuetify'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Customer',
  components: { CustomerModified, CustomeRegister, StatisticsCardVertical },
  data() {
    return {
      expanded: [],
      singleExpand: true,
      search: '',
      filtreTable: '',
      createCustomer: false,
      modifyCustomer: false,
      listCity: [],
      customer_selected: {},
      customer_list: [],
      customer_backup: [],
      paginationData:{},
    }
  },
  mounted() {
    this.loadCustomer()
  },
  methods: {
    filterCustomer() {
      this.customer_list = this.customer_list.filter(cust => (cust.cust_city === this.filtreTable)).length
      // eslint-disable-next-line radix
        ? this.customer_list.filter(cust => (cust.cust_city === this.filtreTable)) : this.customer_backup
    },
    closeCustomerRegister() {
      this.createCustomer = false
      this.loadCustomer()
    },
    closeModifCust() {
      this.modifyCustomer = false
      this.loadCustomer()
    },
    loadCustomer() {
      this.$axios.get(this.$endpoint.LoadCustomer).then(rp => {
        this.customer_list = rp.data
        this.customer_backup = rp.data
        // eslint-disable-next-line func-names
        const city = []
        city.push('toute les villes')
        this.customer_list.forEach(item => {
          if (city.includes(item.cust_city) === false) {
            city.push(item.cust_city)
          }
        })
        this.listCity = city
        this.createCustomer = false
      })
    },
    getItemesByPage(ele){
      this.paginationData = ele;
    },
    exportToExcel(){
      try {
        const custom_data = [];
        console.log(this.paginationData.pageStart, this.paginationData.pageStop);
        if(this.paginationData.pageStart >= 0 && this.paginationData.pageStop > 0){
          for (let i = this.paginationData.pageStart; i < this.paginationData.pageStop ; i++  ){
            custom_data.push(this.customer_list[i]);
          }
            }else{
          for (let i = 0; i < 10; i++  ){
            custom_data.push(this.customer_list[i]);
          }
        }
        console.log(custom_data);
        const excelData=[];
        custom_data.map(item => {
          excelData.push({
            Date_enregistrement:item.register_date,
            Genre: item.cust_sexe,
            Nom: item.cust_lastname,
            Prenom: item.cust_firstname,
            Nationalite:item.cust_nationality,
            Contact:item.cust_contact,
            Type_de_piece:item.cust_type_id,
            Reference_de_la_piece:item.cust_reference_id,
            Date_expiration_de_la_piece:item.cust_id_expiration_date,
            Email:item.cust_email,
            Date_de_Naissance:item.cust_birthday,
            Lieu_de_naissance:item.cust_birth_place,
            Nom_du_pere:item.cust_father_name,
            Nom_de_la_mere:item.cust_mother_name,
            Pays:item.cust_country,
            Ville:item.cust_city,
            Profession:item.cust_job,
            Domicile:item.cust_place_residence,
            Date_entrer_en_ci:item.cust_date_of_entering_ivory_coast,
          })
        })
        json2excel({
          data:  excelData,
          name: 'list_client',
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        console.error('export error: ', e);
      }
    },

    modify_customer(customer) {
      this.customer_selected = customer
      this.modifyCustomer = true
    },
  },
  setup() {
    const totalProfit = {
      statTitle: 'Base de Donnée Client',
      icon: mdiAccount,
      color: 'success',
      subtitle: 'Clients enregistré',
    }
    return {
      headers: [
        { text: 'DATE INSCRIPTION', value: 'register_date' },
        { text: 'CIVILITÉ', value: 'cust_sexe' },
        { text: 'NOM', value: 'cust_lastname' },
        { text: 'PRÉNOM', value: 'cust_firstname' },
        { text: 'NATIONALITE', value: 'cust_nationality' },
        { text: 'CONTACT', value: 'cust_contact' },
        { text: 'PIÈCE', value: 'cust_type_id' },
        { text: 'EMAIL', value: 'cust_email' },
      ],
      totalProfit,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCloseCircle,
      },
    }
  },

}

</script>

<style scoped>

</style>
