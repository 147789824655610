 const CountryRegion = [
    'Abidjan (District autonome)',
    'Yamoussoukro (District autonome)',
    'Agboville (Région de l\'Agnéby-Tiassa)',
    'Sikensi (Région de l\'Agnéby-Tiassa)',
    'Tiassalé (Région de l\'Agnéby-Tiassa)',
    'Taabo (Région de l\'Agnéby-Tiassa)',
    'Koro (Région du Bafing)',
    'Ouaninou (Région du Bafing)',
    'Touba (Région du Bafing)',
    'Boundiali (Région du Bagoué)',
    'Kouto (Région du Bagoué)',
    'Tengréla (Région du Bagoué)',
    'Didievi (Région du Bélier)',
    'Tiébissou (Région du Bagoué)',
    'Toumodi (Région du Bagoué)',
    'Djekanou (Région du Bagoué)',
    'Kounahiri (Région du Béré)',
    'Mankono (Région du Béré)',
    'Dianra (Région du Béré)',
    'Bouna (Région de Bounkani)',
    'Doropo (Région du Béré)',
    'Nassian (Région du Béré)',
    'Tehini (Région du Béré)',
    'Bloléquin (Région du Cavally)',
    'Guiglo (Région du Cavally)',
    'Toulepleu (Région du Cavally)',
    'Kaniasso (Région du Folon)',
    'Minignan (Région du Folon)',
    'Botro ( Région du Gbêkê)',
    ' Bouaké ( Région du Gbêkê)',
    'Sakassou ( Région du Gbêkê)',
    'Fresco (Région du Gbôklé)',
    'Sassandra (Région du Gbôklé)',
    'Gagnoa (Région du Gôh)',
    'Oumé (Région du Gôh)',
    ' Bangolo (Région du Guémon)',
    'Duékoué (Région du Guémon)',
    'Kouibly (Région du Guémon)',
    'Facobli (Région du Guémon)',
    'Abengourou (Région de l\'Indénié-Djuablin)',
    'Agnibilékrou (Région de l\'Indénié-Djuablin)',
    ' Béttié (Région de l\'Indénié-Djuablin)',
    'Madinani (Région du Kabadougou)',
    'Odienné (Région du Kabadougou)',
    'Gbeleban (Région du Kabadougou)',
    'Seguelon (Région du Kabadougou)',
    'Buyo (Région de la Nawa)',
    'Guéyo (Région de la Nawa)',
    'Meagui (Région de la Nawa)',
    'Soubré (Région de la Nawa)',
    'Divo (Région du Lôh-Djiboua)',
    'Guitry (Région du Lôh-Djiboua)',
    'Lakota (Région du Lôh-Djiboua)',
    'Daoukro (Région de l\'Iffou)',
    'M’Bahiakro (Région de l\'Iffou)',
    'Ouellé (Région de l\'Iffou)',
    'Prikro (Région de l\'Iffou)',
    'Arrah (Région du Moronou)',
    'Bongouanou (Région du Moronou)',
    ' M’Batto (Région du Moronou)',
    'Bocanda (Région du N\'Zi)',
    'Dimbokro (Région du N\'Zi)',
    'Kouassi-Kouassikro (Région du N\'Zi)',
    'Dabou (Région du Grands Ponts)',
    'Grand-Lahou (Région du Grands Ponts)',
    'Jacqueville (Région du Grands Ponts)',
    'Adzopé (Région de La Mé)',
    'Akoupé (Région de La Mé)',
    'Alépé (Région de La Mé)',
    ' Yakassé-Attobrou (Région de La Mé)',
    'Biankouma (Région du Tonkpi)',
    'Danané (Région du Tonkpi)',
    'Man (Région du Tonkpi)',
    'Zouan-Hounien (Région du Tonkpi)',
    'Sipilou (Région du Tonkpi)',
    'Daloa ( Région du Haut-Sassandra)',
    'Issia ( Région du Haut-Sassandra)',
    'Vavoua ( Région du Haut-Sassandra)',
    'Zoukougbeu ( Région du Haut-Sassandra)',
    'Bouaflé (Région de la Marahoué)',
    'Sinfra (Région de la Marahoué)',
    'Zuénoula (Région de la Marahoué)',
    'Bonon (Région de la Marahoué)',
    'Dikodougou (Région du Poro)',
    'Korhogo  (Région du Poro)',
    'Sinématiali (Région du Poro)',
    'M’Bengué (Région du Poro)',
    'Ferkessédougou (Région du Tchologo)',
    'Ouangolodougou (Région du Tchologo)',
    'Kong (Région du Tchologo)',
    'Dabakala (Région du Hambol)',
    'Katiola (Région du Hambol)',
    'Niakaramadougou (Région du Hambol)',
    'Bondoukou (Région du Gontougou)',
    'Koun-Fao (Région du Gontougou)',
    'Sandegue (Région du Gontougou)',
    'Tanda (Région du Gontougou)',
    'Transua (Région du Gontougou)',
    'San-Pédro (Région de San-Pédro)',
    'Tabou (Région de San-Pédro)',
    'Aboisso (Région du Sud-Comoé)',
    'Adiaké (Région du Sud-Comoé)',
    'Grand-Bassam (Région du Sud-Comoé)',
    'Tiapoum (Région du Sud-Comoé)',
    'Kani (Région du Worodougou)',
    'Séguéla (Région du Worodougou)',
];
 export default CountryRegion;